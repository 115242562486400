/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.imagemError{
    margin-top: 30px;
}

.swal2-title{
    font-size: 17px !important;
}

.swal2-html-container{
    font-size: 16px;
}

.spin{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
 }
 ion-spinner {
    width: 90px;
    height: 90px;
    color: #038bbb;
 }

 .uk-form-danger {
  border: 2px solid #ff0000 !important;
}

small, strong, span, samp, p{
    font-family: 'Poppins', sans-serif !important;
 }

ion-label, ion-button, ion-input, ion-checkbox, ion-title, ion-list-header, ion-note, ion-searchbar {
    font-family: 'Poppins', sans-serif !important;
}

h1, h2, h3, h4, h5, h6{
    font-family: 'Poppins', sans-serif !important;
}

@media only screen and (max-width: 440px){
    .swal2-title{
        font-size: 1.24em !important;
    }

    .swal2-html-container{
        font-size: 0.9em !important;
    }
    .error-msg {
      color: #a94442;
    }
    .fix-error-icon {
      top: 27px;
    }
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
    display: contents;
}
